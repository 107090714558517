import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cleave from "cleave.js/react"
import "cleave.js/dist/addons/cleave-phone.us"

import { AcceptHosted } from "react-authorize-net"

import { stateLabelValues } from "../utils/Usstates"

const apiLoginId = process.env.GATSBY_AUTHORIZENET_LOGIN_ID
const clientKey = process.env.GATSBY_AUTHORIZENET_CLIENT_KEY

// NOTES:
// CHANGE PRACTICE NAME IN "hostedPaymentSettings"
// ADD API KEYS IN .ENV

const makeAPayment = ({ pageContext, location, data }) => {
  const [form, setForm] = useState({
    patientFirstName: "",
    patientLastName: "",
    patientId: "",
    firstName: "",
    lastName: "",
    street: "",
    street2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    paymentAmount: ""
  })
  const [error, setError] = useState(false)
  const [token, setToken] = useState("")

  const post = data.allUniquePagesJson.nodes[0]

  const language = pageContext.language
  const pageUrl = pageContext.url
  const siteUrl = data.site.nodes[0].siteMetadata.siteUrl
  const redirects = post.redirects

  const stringifyUrl = page => {
    return `"${siteUrl}${page}"`
  }

  const postPayment = async data => {
    const body = {
      getHostedPaymentPageRequest: {
        merchantAuthentication: {
          name: apiLoginId,
          transactionKey: clientKey
        },
        transactionRequest: {
          transactionType: "authCaptureTransaction",
          amount: data.paymentAmount.replace(/[$]/g, ""),
          customer: {
            id: data.patientId,
            email: data.email
          },
          billTo: {
            firstName: data.firstName,
            lastName: data.lastName,
            address: data.street,
            city: data.city,
            state: data.state,
            zip: data.zip,
            country: "US",
            phoneNumber: data.phone
          },
          userFields: {
            userField: [
              {
                name: "patient_name",
                value: `${data.patientFirstName} ${data.patientLastName}`
              },
              {
                name: "patient_id",
                value: data.patientId
              }
            ]
          }
        },
        hostedPaymentSettings: {
          setting: [
            {
              settingName: "hostedPaymentReturnOptions",
              settingValue:
                '{"showReceipt": true, "url": ' +
                stringifyUrl(redirects.approved) +
                ', "urlText": "Continue", "cancelUrl": ' +
                stringifyUrl(pageUrl) +
                ', "cancelUrlText": "Cancel"}'
            },
            {
              settingName: "hostedPaymentButtonOptions",
              settingValue: '{"text": "Pay"}'
            },
            {
              settingName: "hostedPaymentStyleOptions",
              settingValue: '{"bgColor": "#05a7e8"}'
            },
            {
              settingName: "hostedPaymentPaymentOptions",
              settingValue:
                '{"cardCodeRequired": false, "showCreditCard": true, "showBankAccount": true}'
            },
            {
              settingName: "hostedPaymentSecurityOptions",
              settingValue: '{"captcha": false}'
            },
            {
              settingName: "hostedPaymentShippingAddressOptions",
              settingValue: '{"show": false, "required": false}'
            },
            {
              settingName: "hostedPaymentBillingAddressOptions",
              settingValue: '{"show": true, "required": true}'
            },
            {
              settingName: "hostedPaymentCustomerOptions",
              settingValue:
                '{"showEmail": true, "requiredEmail": true, "addPaymentProfile": true}'
            },
            {
              settingName: "hostedPaymentOrderOptions",
              settingValue: '{"show": true, "merchantName": "MOSDI"}'
            }
            // {
            //   settingName: "hostedPaymentIFrameCommunicatorUrl",
            //   settingValue: '{"url": "https://mysite.com/special"}'
            // }
          ]
        }
      }
    }

    const post = await fetch("https://api.authorize.net/xml/v1/request.api", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })

    if (post.ok) {
      const res = await post.json()
      setToken(res.token)
      setTimeout(() => {
        clickAuthorizeButton()
      }, 200)
    }
  }

  const handleChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.rawValue || e.target.value
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (
      form.firstName === "" ||
      form.lastName === "" ||
      form.patientFirstName === "" ||
      form.patientLastName === "" ||
      form.street === "" ||
      form.city === "" ||
      form.state === "" ||
      form.zip === "" ||
      form.phone === "" ||
      form.email === "" ||
      form.paymentAmount === ""
    ) {
      setError(true)
    } else {
      setError(false)
      postPayment(form)
    }
  }

  const button = useRef(null)

  const clickAuthorizeButton = () => {
    button.current.click()
  }

  return (
    <Layout language={language}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
      />

      <div className="columns top-section color-back">
        <div className="column is-4"></div>
        <div className="column">
          <h1 style={{ marginTop: 0, marginBottom: 0 }}>{post.heading}</h1>
        </div>
        <div className="column is-4"></div>
      </div>

      <div className="columns make-a-payment">
        <div className="payment-form__container">
          <form onSubmit={handleSubmit} className="payment-form__fields">
            <label>
              <p className="mt-0">
                Patient Name <span>*</span>
              </p>
              <div className="input-group split">
                <input
                  className={
                    error && form.patientFirstName === "" ? "required" : ""
                  }
                  type="text"
                  placeholder="First Name"
                  name="patientFirstName"
                  value={form.patientFirstName}
                  onChange={handleChange}
                />
                <input
                  className={
                    error && form.patientLastName === "" ? "required" : ""
                  }
                  type="text"
                  placeholder="Last Name"
                  name="patientLastName"
                  value={form.patientLastName}
                  onChange={handleChange}
                />
              </div>
            </label>

            <label>
              <p className="mt-0">Patient ID</p>
              <div className="input-group half">
                <input
                  type="text"
                  placeholder="Patient ID"
                  name="patientId"
                  value={form.patientId}
                  onChange={handleChange}
                />
              </div>
            </label>

            <label>
              <p>
                Cardholder Name <span>*</span>
              </p>
              <div className="input-group split">
                <input
                  className={error && form.firstName === "" ? "required" : ""}
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  value={form.firstName}
                  onChange={handleChange}
                />
                <input
                  className={error && form.lastName === "" ? "required" : ""}
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  value={form.lastName}
                  onChange={handleChange}
                />
              </div>
            </label>

            <label>
              <p>
                Address <span>*</span>
              </p>
              <div className="input-group">
                <input
                  className={error && form.street === "" ? "required" : ""}
                  type="text"
                  placeholder="Street Address"
                  name="street"
                  value={form.street}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Address Line 2"
                  name="street2"
                  value={form.street2}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group split">
                <input
                  className={error && form.city === "" ? "required" : ""}
                  type="text"
                  placeholder="City"
                  name="city"
                  value={form.city}
                  onChange={handleChange}
                />
                <select
                  className={error && form.state === "" ? "required" : ""}
                  placeholder="select"
                  name="state"
                  value={form.state}
                  defaultValue={form.state}
                  onChange={handleChange}>
                  <option value="" disabled selected>
                    Select State...
                  </option>
                  {stateLabelValues.map(state => (
                    <option key={state.label} value={state.value}>
                      {state.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-group half">
                <input
                  className={error && form.zip === "" ? "required" : ""}
                  type="number"
                  min="0"
                  placeholder="ZIP Code"
                  name="zip"
                  value={form.zip}
                  onChange={handleChange}
                />
              </div>
            </label>

            <label>
              <p>
                Phone <span>*</span>
              </p>
              <div className="input-group half">
                <Cleave
                  className={error && form.phone === "" ? "required" : ""}
                  type="tel"
                  name="phone"
                  placeholder="Phone"
                  options={{
                    phone: true,
                    phoneRegionCode: "us"
                  }}
                  onChange={handleChange}
                />
              </div>
            </label>

            <label>
              <p>
                Email <span>*</span>
              </p>
              <div className="input-group half">
                <input
                  className={error && form.email === "" ? "required" : ""}
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                />
              </div>
            </label>

            <label>
              <p>
                Payment Amount <span>*</span>
              </p>
              <div className="input-group half">
                <Cleave
                  className={
                    error && form.paymentAmount === "" ? "required" : ""
                  }
                  name="paymentAmount"
                  placeholder="Payment Amount"
                  options={{
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                    prefix: "$"
                  }}
                  onChange={handleChange}
                />
              </div>
            </label>
            <button className="pay-btn" type="submit">
              Pay Now
            </button>
          </form>

          <div style={{ display: "none" }}>
            <AcceptHosted formToken={token} mode="production" type="redirect">
              <button ref={button}></button>
            </AcceptHosted>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query MyQuery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        metaDescription
        metaTitle
        redirects {
          approved
          declined
        }
      }
    }
    site: allSite {
      nodes {
        siteMetadata {
          siteUrl
          fullPracticeName
        }
      }
    }
  }
`

export default makeAPayment
